const sendToConsole = (level, ...args) => {
  args.unshift(`<eqx> (${new Date().toISOString()})`);
  // eslint-disable-next-line no-console
  console[level](...args);
};

const logger = {
  debug: (...args) => {
    sendToConsole("debug", ...args);
  },
  error: (...args) => {
    sendToConsole("error", ...args);
  },
  info: (...args) => {
    sendToConsole("info", ...args);
  },
  log: (...args) => {
    sendToConsole("log", ...args);
  },
  warn: (...args) => {
    sendToConsole("warn", ...args);
  },
};

export default logger;
